
import { createApp } from 'vue'
import App from '@/App.vue'
import axios from 'axios'

import { IonicVue } from '@ionic/vue';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './css/custom.css';
import store from './store'
import router from '@/router';
import './registerServiceWorker'

import VueSignaturePad from 'vue-signature-pad';

axios.defaults.withCredentials = true;
// axios.defaults.baseURL = 'http://localhost:8000/'
axios.defaults.baseURL = 'https://dashboard.brightchecker.com'
// axios.defaults.baseURL = 'https://staging.brightchecker.com'
// axios.defaults.baseURL = 'https://brightchecker.quickfire.digital/';

import { Network } from '@capacitor/network';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

const logCurrentNetworkStatus = async () => {
  const status = await Network.getStatus();
  store.dispatch("auth/network", status)
};
logCurrentNetworkStatus();

Network.addListener('networkStatusChange', status => {
  console.log('Network status changed', status);
  store.dispatch("auth/network", status)
});

startLoggedIn();

function startLoggedIn(){
    const app = createApp(App)
      .use(store)
      .use(IonicVue)
      .use(router)
      .use(defineCustomElements)
      .use(VueSignaturePad);
    router.isReady().then(() => {
      
      app.config.devtools = true 
      app.mount('#app');
    });

    Sentry.init({
      app,
      dsn: "https://7845df8f71ca4cbab39ffaa91c07a536@o144467.ingest.sentry.io/6564207",
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", "my-site-url.com", /^\//],
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
    
}

