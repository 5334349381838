import axios from 'axios'

export default {
  namespaced: true,

  state: {
    authenticated: null,
    user: null,
    token: "",
    network : {}
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },
    user (state) {
      return state.user
    },
    token (state) {
      return state.token
    },
    network (state) {
      return state.network
    },
    getJob: (state) => (id) => {
      var jobs = JSON.parse(state.user).jobs;
      if(jobs.length > 0){
        for(var i = 0; i < jobs.length; i++){
          let current = jobs[i];
          if(current.id == id){
            return current
          }
        }
      }
      return false;
    }
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    },

    SET_TOKEN (state, value) {
      state.token = value
    },
    SET_NETWORK (state, value) {
      state.network = value;
    }
  },

  actions: {
    signIn ({ commit }, credentials) {
      return new Promise((resolvePromise, rejectPromise) => {
        return axios.get('/api/csrf-cookie').then(() => {
          axios.post('/api/login', credentials).then(resp => {
            var token = resp.data.access_token;
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            commit('SET_AUTHENTICATED', true)
            commit('SET_TOKEN', token)
            resolvePromise(resp)
          }).catch(function () {
            rejectPromise()
          })
        })
      })
    },

    async signOutAndClear ({ commit }) {
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
      commit('SET_TOKEN', null)
      return await axios.post('api/logout').then(function () {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
        commit('SET_TOKEN', null)
        commit('brightchecker/SET_JOBS', null, { root: true })
        commit('brightchecker/SET_CURRENT_JOB', null, { root: true })
        commit('brightchecker/SET_CURRENT_SECTION', null, { root: true })
        commit('brightchecker/SET_CURRENT_ITEM', null, { root: true })
        commit('images/SET_IMAGES', null, { root: true })
        
      }).catch(function () {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
        commit('SET_TOKEN', null)
      })
    },

    async signOut ({ commit }) {
      commit('SET_AUTHENTICATED', false)
      commit('SET_TOKEN', null)
      return await axios.post('api/logout').then(function () {
        commit('SET_AUTHENTICATED', false)
        commit('SET_TOKEN', null)
        
      }).catch(function () {
        commit('SET_AUTHENTICATED', false)
        commit('SET_TOKEN', null)
      })
    },

    network ({ commit }, network) {
      commit('SET_NETWORK', network)
      return true;
    },

    logout ({ commit }) {
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
      commit('SET_TOKEN', null)
    }
  }
}