<template>
  <ion-content color="white" fullscreen class="ion-padding" scroll-y="false">
    <div>
        <div class="close-modal" @click="closeModal">
            <uil-multiply size="20px" />
        </div>
    </div>
    <ion-slides pager="true">
        <ion-slide>
            <img src="assets/app_images/home_screen.jpg"/>
            <h2>Home Screen</h2>
            <p>Displays Job details, click sync icon top right to update. Click the icon next to ‘Your Jobs’ to filter/sort jobs. Click map arrow icon to open google maps. Click ‘list’ icon to see asset notes. Click ‘View’ to enter a job. Click the three parallel lines top left to go to the menu screen.</p>
        </ion-slide>

        <ion-slide>
            <img src="assets/app_images/menu_screen.jpg"/>
            <h2>Menu Screen</h2>
            <p>Click ‘Home’ to go to the home screen. Click ‘Sync’ to sync your app with the dashboard . Click ‘logout’ to logout of the app. Click ‘Logout and Clear Data’ to delete the app, job data and survey responses from your device. User Id is displayed at the bottom of the screen.</p>
        </ion-slide>

        <ion-slide>
            <img src="assets/app_images/sections_items.jpg"/>
            <h2>Home Job Screen (1 of 2)</h2>
            <p>Swipe sections left & right. A section tick = all questions in the section’s items have responses. Click ‘Notes’ icon to see property notes. Click arrow icon to go to google maps. Click ‘grid’ con to go to the All Sections  / Add Section Screen. Click ‘&lt;‘ to return to Home screen. Click the Click ‘submit job’ to submit the job.</p>
        </ion-slide>

        <ion-slide>
            <img src="assets/app_images/sections_items.jpg"/>
            <h2>Home Job Screen (2 of 2)</h2>
            <p>Items swipe up & down. Red ball = required questions –with no response, grey Ball = other questions with no response, green ball = all questions with a response. Item tick = all questions in the item have a response. Click ‘Add Item’ to open the add item menu. Click ‘Add Item’ to open the add item menu. Click an item to see items questions.</p>
        </ion-slide>

        <ion-slide>
            <img src="assets/app_images/add_item.jpg"/>
            <h2>Add Item Screen</h2>
            <p>Select an item to duplicate and a section to place it in. Click the ‘X’ icon next to a question to delete it. Click ‘Save’ to create the item once all item selections/ changes have been made.  Click ‘Save & Add Another’ to save the item and to add another item. Click ‘Cancel’ to stop adding the item.</p>
        </ion-slide>

        <ion-slide>
            <img src="assets/app_images/item_questions.jpg"/>
            <h2>Item Questions</h2>
            <p>Swipe questions up and down. Respond as invited. Click ‘>’ to advance through items in a section and ‘&lt;‘ to move back. Click ‘Items’ icon  to return to section/items screen.</p>
        </ion-slide>

        <ion-slide>
            <img src="assets/app_images/grid.jpg"/>
            <h2>All Sections ‘Grid’ View  / Add Section</h2>
            <p>Swipe up and down to see all job sections. Click a section to see that section’s items. Underneath these you can see which sections can be duplicated. Click a section to go to that section. On the section you want to duplicate click ‘+Add’. Accept the original section name or amend as appropriate. Click ‘Save’ to add the new section. Click ‘X’ to stop adding a section.</p>
        </ion-slide>

        <ion-slide>
            <img src="assets/images/phone.png"/>
            <h2>Get Started</h2>
            <ion-button color="secondary" fill="solid" @click="closeModal">Continue</ion-button>
        </ion-slide>

      </ion-slides>
  </ion-content>
</template>

<script>
import { IonContent, IonSlides, IonSlide, modalController, IonButton } from '@ionic/vue';
import { UilMultiply } from '@iconscout/vue-unicons'

export default {
    name: 'HelpModal',
    components: { IonContent, IonSlides, IonSlide, UilMultiply, IonButton }, 
    methods: {
      closeModal() {
        modalController.dismiss();
      }
    }
};
</script>

<style scoped lang="scss">

    ion-slides {
      height: 100%;
    }

    .swiper-slide {
      display: block;
    }

    .swiper-slide h2 {
      margin-top: 25px;
      font-size: 20px;
      line-height: 24px;
      font-weight: 800;
    }

    .swiper-slide img {
        max-height: 50%;
        max-width: 80%;
        margin: 25px 0 0px;
        pointer-events: none;
    }

    p {
      padding: 0 40px;
      font-size: 14px;
      line-height: 21px;
      color: #373636;
    }
</style>