import axios from 'axios'
import helpers from '../../helpers/data-handling.js';


function sortData(data){
    let original = Object.assign({}, data);
    return JSON.stringify(original)
}

export default {
    namespaced: true,
    state: {
        jobs : null,
        current_job: null,
        current_asset: null,
        current_section: null,
        current_item: null
    },

    getters: {
        jobs (state) {
          return JSON.parse(state.jobs)
        },
        current_job (state) {
          return JSON.parse(state.current_job)
        },
        current_asset (state) {
          return state.current_asset
        },
        current_section (state) {
          return JSON.parse(state.current_section)
        },
        current_item (state) {
          return JSON.parse(state.current_item)
        },
    },

    mutations: {
      SET_JOBS (state, value) {
        state.jobs = value
      },
      SET_CURRENT_JOB (state, value) {
        if(value != null || value != undefined || value != ""){  
          state.current_job = JSON.stringify(Object.assign({}, value))
        } else {
          state.current_job = null
        }
      },

      SET_CURRENT_ASSET (state, value) {
          state.current_asset = value
      },
      SET_CURRENT_SECTION (state, value) {
        if(value != null || value != undefined || value != ""){  
          state.current_section = JSON.stringify(Object.assign({}, value))
        } else {
          state.current_section = null
        }
      },
      SET_CURRENT_ITEM (state, value) {
          if(value != null || value != undefined || value != ""){  
            state.current_item = JSON.stringify(Object.assign({}, value))
          } else {
            state.current_item = null
          }
      }
    },

    actions: {
      async me ({ commit, getters, rootState }) {
        let user = JSON.parse(rootState.auth.user);

        if(user == null){
          return axios.get('/api/me').then((response) => {
            let userObj = response.data.user;
            let images = userObj.images;
            // let imageString = sortData(images);
            let jobsString = sortData(userObj.jobs);
            delete userObj.jobs
            delete userObj.images

            console.log("243534", userObj)

            let userString = sortData(userObj);

            commit('auth/SET_AUTHENTICATED', true, { root: true })
            commit('SET_JOBS', jobsString)
            commit('auth/SET_USER', userString, { root: true })
            commit('images/SET_IMAGES', images, { root: true })
          }).catch(() => {
            commit('auth/SET_AUTHENTICATED', false, { root: true })
            commit('auth/SET_USER', null, { root: true })
          })
        } else {
          // sync jobs instead
          commit('auth/SET_AUTHENTICATED', true, { root: true })

          let ids = [];
          for(let i = 0; i < getters.jobs.length; i++){
            ids.push(getters.jobs[i].id);
          }
          let data = {
            ids : ids
          }
          return axios.post('/api/jobUpdate', data).then((response) => {
            let user_id = response.data.user.id;
  
            let userObj = response.data.user;
            let images = userObj.images;
            // let imageString = sortData(images);
            let jobsString = sortData(userObj.jobs);
            delete userObj.jobs
            let userString = sortData(userObj);

            // new user logged in
            if(user_id != user.id){

  
              commit('auth/SET_AUTHENTICATED', true, { root: true })
              commit('SET_JOBS', jobsString)
              commit('auth/SET_USER', userString, { root: true })
              commit('images/SET_IMAGES', images, { root: true })
            } else {

              let current_jobs = getters.jobs;
              let ids = Object.keys(getters.jobs);
              let data = {
                ids : ids
              }
      
              axios.post('/api/jobUpdate', data).then((response) => {
                let userObj = response.data.user;
                let jobsObj = userObj.jobs;
                console.log(response.data.needs_adding, response.data.needs_removing)
      
                if(response.data.needs_adding && response.data.needs_adding.length > 0){
                  let updates = response.data.needs_adding;
                  for(const u of updates){
                    current_jobs[u] = jobsObj[u];
                  }
                }
        
                if(response.data.needs_removing && response.data.needs_removing.length > 0){
                  let removes = response.data.needs_removing;
                  for(const r of removes){
                    delete current_jobs[r];
                    for(let prop in rootState.images.images){
                      let current = rootState.images.images[prop];
                      if(current.job_id == r){
                        commit('images/DELETE_IMAGE', current.id, { root: true })
                      }
                    }
                  }
                }
      
                let jobsString = sortData(current_jobs);
                commit('SET_JOBS', jobsString)
      
                let new_images = userObj.images;
                let img_list = Object.assign(new_images, rootState.images.images);
      
                commit('images/SET_IMAGES', img_list, { root: true })
        
              }).catch(() => {
                return false;
              })
            }
          }).catch(() => {
            commit('auth/SET_AUTHENTICATED', false, { root: true })
            commit('auth/SET_USER', null, { root: true })
            commit('SET_JOBS', null)
            commit('images/SET_IMAGES', null, { root: true })
          })
        }
      },

      async saveJob({ commit, getters }, job){
        let jobs = getters.jobs;
        let item = getters.current_item;
        let section = getters.current_section;

        job.survey.sections[section.id].items[item.id] = item;
        jobs[job.id] = job;

        let jobsString = sortData(jobs);
        commit('SET_JOBS', jobsString)
        commit('SET_CURRENT_JOB', job)
      },

      async jobUpdate ({ commit, getters, rootState }) {
        let current_jobs = getters.jobs;
        let ids = Object.keys(getters.jobs);
        let data = {
          ids : ids
        }

        await axios.post('/api/jobUpdate', data).then((response) => {
          let userObj = response.data.user;
          let jobsObj = userObj.jobs;

          if(response.data.needs_adding && response.data.needs_adding.length > 0){
            let updates = response.data.needs_adding;
            for(const u of updates){
              current_jobs[u] = jobsObj[u];
            }
          }
  
          if(response.data.needs_removing && response.data.needs_removing.length > 0){
            let removes = response.data.needs_removing;
            for(const r of removes){
              delete current_jobs[r];
              for(let prop in rootState.images.images){
                let current = rootState.images.images[prop];
                if(current.job_id == r){
                  commit('images/DELETE_IMAGE', current.id, { root: true })
                }
              }
            }
          }

          let jobsString = sortData(current_jobs);
          commit('SET_JOBS', jobsString)

          let new_images = userObj.images;
          let img_list = Object.assign(new_images, rootState.images.images);

          commit('images/SET_IMAGES', img_list, { root: true })
  
        }).catch(() => {
          return false;
        })
      },
      async sendJob({ getters, commit, dispatch, rootGetters, rootState }, options){
        let jobs = getters.jobs;
        let current = jobs[options.id];
        let organisation = JSON.parse(rootGetters['auth/user']).organisation;

        current.timing = options.type
        current = helpers.tidyJob(current);

        return new Promise((resolve) => {
          dispatch("images/job_images", current.id, { root: true }).then((images) => {  
            let data = {
              job : current,
              images : images,
              organisation : organisation
            }
            axios.put('/api/saveRequest', data).then(() => {
            }).catch((e) => {
              console.log("catch", e)
            })
    
            axios.put('/api/job', data).then((response) => {
              if(response.data.status == "success"){
                let userObj = response.data.user;
                // overwrite app job based on response from submission
                jobs[current.id] = userObj.jobs[current.id];
                let jobsString = sortData(jobs);
                commit('SET_JOBS', jobsString)
  
                let new_images = userObj.images;
                let img_list = Object.assign(new_images, rootState.images.images);
      
                commit('images/SET_IMAGES', img_list, { root: true })
                resolve(true);
              } else {
                resolve(false);
              }
    
            }).catch((e) => {
              console.log("catch", e)
            })
          });
        });
      },
    }
}