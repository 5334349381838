<template>
  <ion-content color="white" class="ion-padding" scroll-y="false">
        <div class="saving-content">
            <div class="saving-text first">Loading...</div>
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
            <div class="saving-text second">Please wait...</div>
        </div>
  </ion-content>
</template>

<script>
import { IonContent, IonProgressBar } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LoadingModal',
    data() {
        
    },
    mounted() {
        
    },

    methods : {

    },
    components: { IonContent, IonProgressBar }
});

</script>

<style scoped lang="scss">
    .saving-content {
        .saving-text {
            color: #373636;
            text-align: center;
            font-size: 20px;
            font-weight: 800;
            font-family: 'Raleway', sans-serif;
            &.first {
                margin-bottom: 20px;
            }
            &.second {
                margin-top: 20px;
            }
        }
    }

</style>