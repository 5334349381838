import Vuex from 'vuex'
import auth from './modules/auth'
import brightchecker from './modules/brightchecker'
import images from './modules/images'
import VuexPersistence from 'vuex-persist'
import localForage from 'localforage';

const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
  supportCircular: true,

});

export default new Vuex.Store({
  modules: {
    auth,
    brightchecker,
    images
  },
  plugins: [
    vuexLocal.plugin
  ],
})
