import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '../store/index'
import axios from 'axios';

const routes = [
  {
    path: '/',
    component: () => import ('../views/Home.vue'),
    name: "home",
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/settings',
    component: () => import ('../views/Settings.vue'),
    name: "settings",
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/login',
    name: "login",
    component: () => import ('../views/Login.vue'),
    meta: {
      hideForAuth: true
    }
  },
  {
    path: '/job/:id',
    name: "job",
    component: () => import ('../views/Job.vue'),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: '/job/:id/section/:section',
    name: "job_section",
    component: () => import ('../views/Job.vue'),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: '/additem/:id/section/:section',
    name: "additem",
    component: () => import ('../views/AddItem.vue'),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: '/sections/:id',
    name: "sections",
    component: () => import ('../views/Sections.vue'),
    meta: {
      requiresAuth : true
    }
  },  
  {
    path: '/job/:id/section/:section/item/:item',
    name: "item",
    component: () => import ('../views/Item.vue'),
    meta: {
      requiresAuth : true
    }
  },  
  {
    path: '/submit/job/:id/',
    name: "jobsubmit",
    component: () => import ('../views/Submit.vue'),
    meta: {
      requiresAuth : true
    }
  },  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})


router.beforeEach(async (to, from, next) => {
  await store.restored

  axios.defaults.headers.common.Authorization =  "Bearer " + store.getters['auth/token'];
  
  // console.log(to.matched)
  if(to.matched.some(record => record.meta.requiresAuth)) { 
    if (store.getters['auth/authenticated']) {
      next();
      return;
    } else {
      next({ path: '/login' });
      return;
    }

  }

  if (to.matched.some(record => record.meta.hideForAuth)) {
    if (store.getters['auth/authenticated']) {
      next({ name: 'home' });
      return;
    } else {
      next();
      return;
    }
  } else {
    next();
    return;
  }

})

export default router
