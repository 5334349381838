export default {
  namespaced: true,
  state: {
    images: {}
  },

  getters: {
    images (state) {
      return state.images
    }
  },

  mutations: {
    SET_IMAGES (state, value) {
      state.images = value
    },
    SET_IMAGE (state, value, id){
      state.images[id] = value
    },
    DELETE_IMAGE (state, id){
      delete state.images[id];
    }
  },

  actions: {
    async saveImage ({ commit, getters }, image) {
      return new Promise((resolve) => {
        let images = getters.images;
        let keys = Object.keys(images)
        console.log(keys, images, image)

        let min = keys.reduce((a, b) => Math.min(a, b))
        if(min < 0){
          min = min - 1;
        } else {
          min = -1;
        }
        console.log("min", min)

        if(!image.id){
          image.id = min;
        }

        images[min.toString()] = image;
        commit('SET_IMAGES', images);
        resolve(min);
      }) 
    },
    job_images ({ getters }, id){
      let imagelist = getters.images;
      console.log("start", id)
      let images = {};
      for(let prop in imagelist){
        if(imagelist[prop].job_id == id){
          images[prop] = imagelist[prop];
        }
      }
      console.log("end", images)
      return images;
    }
  }
}